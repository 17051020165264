<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3">
      <v-row class="my-n8">
        <v-col cols="12">
          <v-card flat v-if="billSetting" class="pa-10 container">
            <v-card-title>
              {{ $t("settings." + billSetting.groupName) }}
              <v-card-subtitle
                v-if="isInRole('64')"
                :class="$vuetify.rtl ? 'mr-auto' : 'ml-auto'"
              >
                <v-btn icon @click="editBill = !editBill">
                  <v-icon>
                    {{ editBill ? "mdi-close" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>
            </v-card-title>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-for="(subItem, ind) in billSetting.settings"
                :key="ind"
              >
                <v-autocomplete
                  :items="accountTree"
                  :label="$t('settings.' + subItem.settingName)"
                  :disabled="!editBill"
                  item-text="accountDisplayName"
                  item-value="guid"
                  v-model="subItem.settingValue"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-card-actions v-if="isInRole('64')">
              <v-btn
                :disabled="!editBill"
                text
                color="primary"
                @click="save(0)"
              >
                {{ $t("save") }}
                <!-- <v-icon>
                  mdi-content-save-outline
                </v-icon> -->
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="container pa-5 mt-3">
      <v-row class="my-n8">
        <v-col cols="12">
          <v-card flat v-if="countrySetting" class="pa-10 container">
            <v-card-title>
              {{ $t("settings." + countrySetting.groupName) }}
              <v-card-subtitle
                v-if="isInRole('64')"
                :class="$vuetify.rtl ? 'mr-auto' : 'ml-auto'"
              >
                <v-btn icon @click="editCountry = !editCountry">
                  <v-icon>
                    {{ editCountry ? "mdi-close" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>
            </v-card-title>

            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="countries"
                  :label="
                    $t('settings.' + countrySetting.settings[0].settingName)
                  "
                  :disabled="!editCountry"
                  item-text="countryName"
                  item-value="guid"
                  v-model="countrySetting.settings[0].settingValue"
                  @change="changeCountry"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="cities"
                  :label="
                    $t('settings.' + countrySetting.settings[1].settingName)
                  "
                  :disabled="!editCountry"
                  item-text="cityName"
                  item-value="guid"
                  v-model="countrySetting.settings[1].settingValue"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-card-actions v-if="isInRole('64')">
              <v-btn
                :disabled="!editCountry"
                text
                color="primary"
                @click="save(1)"
              >
                {{ $t("save") }}
                <!-- <v-icon>
                  mdi-content-save-outline
                </v-icon> -->
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="container pa-5 mt-3">
      <v-row class="my-n8">
        <v-col cols="12">
          <v-card flat v-if="branchSetting" class="pa-10 container">
            <v-card-title>
              {{ $t("settings.branchSetting") }}
              <v-card-subtitle
                v-if="isInRole('64')"
                :class="$vuetify.rtl ? 'mr-auto' : 'ml-auto'"
              >
                <v-btn icon @click="editBranch = !editBranch">
                  <v-icon>
                    {{ editBranch ? "mdi-close" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>
            </v-card-title>

            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-row style="position: relative" no-gutters>
                  <v-col cols="auto" style="width:130px">
                    <v-card
                      class="pa-1"
                      style="position: absolute;top: -20px;z-index: 1;"
                      min-width="110"
                      min-height="110"
                    >
                      <v-img
                        max-width="100"
                        contain
                        max-height="110"
                        :src="branchSetting.branchLogoUrl"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-file-input
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < 2000000 ||
                          $t('error.FileIsTooBig'),
                      ]"
                      prepend-icon="mdi-camera"
                      accept="image/png, image/jpeg, image/bmp"
                      @change="Preview_image"
                      v-model="image"
                      :disabled="!editBranch"
                      :label="$t('settings.branchLogoUrl')"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  :label="$t('settings.branchName')"
                  :disabled="!editBranch"
                  v-model="branchSetting.branchName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  :label="$t('settings.branchNumber')"
                  :disabled="!editBranch"
                  v-model="branchSetting.branchNumber"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  :label="$t('settings.branchAddress')"
                  :disabled="!editBranch"
                  v-model="branchSetting.branchAddress"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-card-actions v-if="isInRole('64')" class="mt-8">
              <v-btn
                :disabled="!editBranch"
                text
                color="primary"
                @click="save(2)"
              >
                {{ $t("save") }}
                <!-- <v-icon>
                  mdi-content-save-outline
                </v-icon> -->
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      settings: [],
      accountTree: [],
      countries: [],
      cities: [],
      editBill: false,
      editCountry: false,
      editBranch: false,
      branchSetting: {},
      image: null,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {},
  computed: {
    billSetting() {
      return this.settings.filter((f) => {
        return f.groupName == "Bill";
      })[0];
    },
    countrySetting() {
      return this.settings.filter((f) => {
        return f.groupName == "Country";
      })[0];
    },
  },
  methods: {
    changeCountry(val) {
      this.cities = this.countries.filter((f) => f.guid == val)[0].cities;
    },
    refreshTable() {
      axios
        .get("Setting")
        .then((response) => {
          this.settings = response.data.data.settings;
          this.branchSetting = response.data.data.branch;

          axios.get("AccountTree").then((resp) => {
            this.accountTree = resp.data.data;
          });

          axios.get("Country").then((resp) => {
            this.countries = resp.data.data;
            var setting = this.settings.filter(
              (f) => f.groupName == "Country"
            )[0];
            if (setting && setting.settings.length > 0) {
              var country = setting.settings.filter(
                (f) => f.settingName == "CountryGuid"
              );
              if (
                country[0].settingValue != null &&
                country[0].settingValue != "" &&
                country[0].settingValue != undefined
              ) {
                this.changeCountry(country[0].settingValue);
              }
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    click() {
      var x = this.editBill;
      this.editBill = !x;
    },
    Preview_image() {
      if (this.image != null && this.image != "")
        this.branchSetting.branchLogoUrl = URL.createObjectURL(this.image);
    },

    save(i) {
      if (i == 0) {
        axios
          .post("Setting/Update", this.billSetting)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              // this.close();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();
            console.log(e);
          });
      }
      if (i == 1) {
        axios
          .post("Setting/Update", this.countrySetting)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              //  this.close();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }

            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();
            console.log(e);
          });
      }
      if (i == 2) {
        var data = new FormData();
        if (this.image != null) {
          data.append("file", this.image);
        }

        for (var key in this.branchSetting) {
          if (this.branchSetting[key])
            data.append(key, this.branchSetting[key]);
        }
        axios
          .post("Branch/UpdateWithImage", data)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
